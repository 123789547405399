import * as React from "react";
import { useEffect } from "react";
import globalThis from "globalthis/polyfill";

export default function Widget(props) {
  function loadViralLoops() {
    const SCRIPT_SRC = "https://app.viral-loops.com/widgetsV2/core/loader.js";
    const scriptExists = document.head.querySelector(
      `script[src="${SCRIPT_SRC}"]`
    );
    if (scriptExists) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = SCRIPT_SRC;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  useEffect(() => {
    loadViralLoops().then(() => {
      const ViralLoops = globalThis["ViralLoops"];
      console.debug("[VL] -> load ViralLoops script", ViralLoops);
    });
  }, []);

  useEffect(() => {
    console.debug("[VL] -> set props.ucid", props.ucid);
  }, ["[VL] -> set props.ucid", props.ucid]);

  return (
    <>
      {props.ucid ? (
        <form-widget ucid={props.ucid} popup={props.popup} />
      ) : null}
    </>
  );
}

Widget.defaultProps = {};
