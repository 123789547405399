

export const homeData = {
  h1: 'Shared Virtual Cards for',
  h2: 'Group Payments',
  button: 'Request Access'
}

export const navbar = {
  logo: 'GrupCard',
  
}