import React from "react";
import { motion } from "framer-motion"
import { variant1 } from '../utils/motion';
import Button from "./button";
import { homeData } from "../assets/data";
import { TypeAnimation } from 'react-type-animation';
import Widget from "./Widget";
import Modal from "react-overlays/Modal";
import styled from "styled-components";

const VIRAL_LOOPS_CAMPAIGN_ID = "GSFkipGfXotf0PjBJNCOntkp6dw";
const RandomlyPositionedModal = styled(Modal)`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 80%;
  z-index: 1040;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);

  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const Home = () => {
  const [show, setShow] = React.useState(false);
  const renderBackdrop = (props) => <Backdrop {...props} />;

  return (
    <motion.div
      variants={variant1(0.5)}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}>
      <RandomlyPositionedModal
        show={show}
        onHide={() => setShow(false)}
        renderBackdrop={renderBackdrop}
        aria-labelledby="modal-label"
      >
        <div>
          <Widget ucid={VIRAL_LOOPS_CAMPAIGN_ID} popup={true} />
        </div>
      </RandomlyPositionedModal>

      <div className=" p-5 text-center  flex justify-center md:justify-center items-center min-h-screen h-full max-w-[1440px] w-full mx-auto">
        <div className=" max-w-xl items-center text-center md:max-w-full w-full mx-auto sm:mx-0 grid gap-10">
          <h1 className="text-white sm:text-7xl text-5xl font-medium font-['Poppins'] leading-[60px]">{homeData.h1} <br/>
          <TypeAnimation
            className="text-black sm:text-7xl text-4xl font-medium font-['Poppins'] leading-[60px] "
            sequence={[
              1000,
              "Booking Trips.",
              1000,
              "Subscriptions.",
              1000,
              "Movie Nights.",
              1000,
              "Squad Shopping.",
              1000,
              "Group Dining.",
              1000,
              "Game Nights.",
              1000,
              "Concerts, Events.",
              1000,
              "Home Bills.",
              1000,
              "Gifts, Donations.",
              1000,
              "Gym fees.",
              1000,
              "Family Expenses.",
              1000,
              "Sport Tickets.",
              1000,
              "Birthday Parties.",
              1000,
              "Ridesharing.",
              1000,
              "Fantasy Draft.",
            ]}
            wrapper="span"
            speed={50}
            style={{ display: 'inline-block' }}
            repeat={Infinity}
          />
          </h1>
          <button onClick={() => {
              console.log("Opening modal");
              setShow(true);
            }} className="text-black md:px-14 md:py-4 mt-130px px-8 py-3 m-auto  w-fit bg-white rounded-full  hover:bg-black duration-200 hover:border-black hover:text-white border-white border-2 gap-12">{homeData.button}</button>
        </div>
      </div>
    </motion.div>
  )
}

export default Home