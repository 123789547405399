import { motion } from "framer-motion"
import { navVariants } from "../utils/motion"
import { useState } from "react"
import { navbar } from "../assets/data"
import Widget from "./Widget";
import Modal from "react-overlays/Modal";
import styled from "styled-components";
import React from "react";

const VIRAL_LOOPS_CAMPAIGN_ID = "GSFkipGfXotf0PjBJNCOntkp6dw";
const RandomlyPositionedModal = styled(Modal)`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 80%;
  z-index: 1040;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);

  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const Navbar = () => {
  let [click, setClick] = useState(false)
  const [show, setShow] = React.useState(false);
  const renderBackdrop = (props) => <Backdrop {...props} />;

  return (
    <motion.div variants={navVariants} initial='hidden' whileInView='show' className=" fixed top-0 left-0 right-0 backdrop-blur bg-white/12 p-5 max-w-[1440px] w-full h-25 mx-auto flex justify-between z-50">
      <RandomlyPositionedModal
        show={show}
        onHide={() => setShow(false)}
        renderBackdrop={renderBackdrop}
        aria-labelledby="modal-label"
      >
        <div>
          <Widget ucid={VIRAL_LOOPS_CAMPAIGN_ID} popup={true} />
        </div>
      </RandomlyPositionedModal>
      <h1 className=" font-semibold text-2xl text-black my-3">{navbar.logo}</h1>
      <div flex gap-5 text-black flex-col-reverse items-end >
      <button onClick={() => {
              console.log("Opening modal");
              setShow(true);
            }} className="md:flex-row md:px-14 md:py-4 mt-130px px-8 py-3 m-auto text-white  w-fit bg-black rounded-full  hover:bg-white duration-200 hover:border-white hover:text-black border-black border-2 gap-12"> Request Access </button>
      </div>
      
    </motion.div>
  )
}

export default Navbar