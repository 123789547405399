import { useEffect, useRef, useState } from 'react';
import Home from './components/home';
import Navbar from './components/navbar';
import Footer from './components/footer';

function App() {
  return (
    <>
      <div className=" bg-gray-00 scroll-smooth snap-y">
        <div className=" animate-slide relative relative bg-[url(assets/bg.png)]  bg-fixed bg-cover bg-left md:bg-center min-h-screen w-full m-0">
          <Navbar />
          <Home />
        </div>
        <Footer/>
      </div>
    </>
  );
}

export default App;
