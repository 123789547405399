import React from "react";
import { navbar } from "../assets/data"

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-600">
      <div>
        <h1 className=" font-semibold text-2xl text-black my-3">{navbar.logo}</h1>
      </div>

      <div>
        <p>
          We care about the protection of your data. Read our{" "}
          <a href="https://grupcard-privacy-policy.s3.us-west-2.amazonaws.com/privacypolicy.html">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500">
              Privacy Policy.
            </span>
          </a>
        </p>
      </div>

      <div>
        <p>GrupCard, Inc. © 2024 All rights reserved.</p>
        <p className="md:text-xs text-xs text-gray-500">
          GrupCard is a financial services platform, not a bank. Card issuing
          services provided by GrupCard’s fintech partner(s).
        </p>
      </div>
    </div>
  );
};

export default Footer;
